.chat-messages {
  height: 19em;
  overflow-y: auto;
  .message {
    width: 75%;
    margin: 1% !important;
    padding-top: 2%;
    padding-left: 2%;
    overflow-wrap: break-word;
    background-color: initial;
  }
  .date {
    padding-left: 3%;
    padding-right: 3%;
    width: 25%;
  }
  .title-message {
    margin-top: 1%;
    margin-left: 2%;
  }
}

.horizontal-scroll {
  height: 14em;
  overflow-y: auto;
}

.quickview {
  top: 51% !important;
  height: 25em;
}

.fixed-button {
  position: fixed !important;
  bottom: 3%;
  right: 3%;
}
