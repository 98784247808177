@import "_fontface";
@include fontface('fontItalic', '../fonts/Exo-Italic', 300, italic, ttf);
@include fontface('fontThin', '../fonts/Exo-Thin', 100, normal, ttf);
@include fontface('fontNormal', '../fonts/Exo-Medium', 100, normal, ttf);
@include fontface('fontBold', '../fonts/Exo-Black', 100, bold, ttf);

$background-color: #303030;
$bleu1: #00b0e5;
$bleu2: #009ddf;
$bleu3: #078ac8;
$bleu3: #0078bc;
$text-color: $bleu3;

$color-primary-0: #00C7FF; // Main Primary color @see http://paletton.com/#uid=73o240k+-++kH+RuD++ZfukR8g+ */
$color-primary-1: #5FDCFF;
$color-primary-2: #13CBFF;
$color-primary-3: #007596;
$color-primary-4: #014254;

$color-secondary-1-0: #FF0040; // Main Secondary color (1) @see http://paletton.com/#uid=73o240k+-++kH+RuD++ZfukR8g+ */
$color-secondary-1-1: #FF5A83;
$color-secondary-1-2: #FF0B48;
$color-secondary-1-3: #DC0037;
$color-secondary-1-4: #7C001F;

$color-secondary-2-0: #64FF00; // Main Secondary color (2) @see http://paletton.com/#uid=73o240k+-++kH+RuD++ZfukR8g+ */
$color-secondary-2-1: #9BFF5A;
$color-secondary-2-2: #6BFF0B;
$color-secondary-2-3: #54D400;
$color-secondary-2-4: #2F7700;

$color-complement-0: #FF8100; // Main Complement color @see http://paletton.com/#uid=73o240k+-++kH+RuD++ZfukR8g+ */
$color-complement-1: #FFAD5A;
$color-complement-2: #FF860B;
$color-complement-3: #F27A00;
$color-complement-4: #874400;

/*------------------------------------------------------------------------------ Title */
.title {
    font-family: "fontThin";
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color;
}


/*------------------------------------------------------------------------------ BUTTON */
.is-danger,
.button.is-danger {
    background-color: $color-secondary-1-0;
}


/*------------------------------------------------------------------------------ Rooms */
.room {
    margin: 0 0.5em;
    min-width: 250px;
    .card-header {
        min-height: 130px;
    }
    @media only screen and (max-width: 769px) {
        .card-header {
            min-height: 1px;
        }
    }
}


/*------------------------------------------------------------------------------ NavBar */
.lumys {
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}

/*------------------------------------------------------------------------------ Divers */
.legende {
    font-size: 12px;
    color: $text-color;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "fontNormal";
    color: $text-color !important;
}