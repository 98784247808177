#largedisplay-container:not(:fullscreen) {
  button#fullscreen-button {
    position: absolute;
    left: 90%;
    top: 82%;
  }
  button#spatialpoint-button {
    position: absolute;
    left: 3%;
    top: 82%;
  }
  div#input-text {
    position: absolute;
    left: 12%;
    top: 82%;
  }
  button#screenshot-button {
    position: absolute;
    left: 3%;
    top: 4%;
  }
}
#largedisplay-container:fullscreen {
  button#fullscreen-button {
    position: absolute;
    left: 95%;
    top: 92%;
  }
  button#spatialpoint-button {
    position: absolute;
    left: 2.5%;
    top: 92%;
  }
  div#input-text {
    position: absolute;
    left: 5.5%;
    top: 92%;
  }
  button#screenshot-button {
    position: absolute;
    left: 2.5%;
    top: 4%;
  }
}

